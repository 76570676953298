<template>
  <div>
    <div class="institution-header contant">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="ruleForm" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="IMEI" prop="imeis">
                                <el-input class="input-w290-h32" size="large" v-model="listQuery.imeis" placeholder="请输入设备/IMEI"></el-input>
                            </el-form-item>                          
                            <el-form-item label="时间范围" prop="warningDateStartDate" class="mr12">
                                <el-date-picker align="right" size="small" v-model="listQuery.warningDateStartDate" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDateAlart"></el-date-picker>
                            </el-form-item>
                            <el-form-item prop="startDate" class="mr12">
                                <span>至</span>
                            </el-form-item>
                            <el-form-item prop="warningDateEndDate">
                                <el-date-picker align="right" size="small" v-model="listQuery.warningDateEndDate" type="datetime" :default-time="new Date()+''" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerEndDateAlart"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="告警类型" prop="alarmTypes">
                                <!-- <el-input class="inputw-w290-h32" v-model="listQuery.alarmTypes" placeholder="请输入机构名称"></el-input> -->
                              <el-select v-model="listQuery.alarmTypes" multiple  placeholder="请选择">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="childFlag">
                                <el-checkbox v-model="listQuery.childFlag">包含下级</el-checkbox>
                            </el-form-item>
                            <tem-btn  type="primary" value="搜索" @click="handleSearch('search')"></tem-btn>
                            <tem-btn  type="info" value="重置" @click="restForm('ruleForm')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.page" :size.sync="listQuery.size"
            :orderBy.sync="listQuery.orderBy" @pagination="getAlarmDetails"></Table>
        </div>
    </div>
    <contact-details @getUserList="getUserList" :toSeeConcat="toSeeConcat" v-model="contactDetailsDialog"
      :userList="userList" />
  </div>
</template>

<script>
  import {getOrgCode} from '@/utils/cookie'
  import {
    alarmDetail
  } from '@/api/statisticalReport/alarmDetails'
  import Tree from '@/components/tree/tree';
  import {
    getUserListByOrganizationId
  } from '@/api/statisticalReport/alarmManagement';
  import {
    getDeviceType
  } from '@/api/statisticalReport/lowBatteryStatistics'
  import Table from '@/components/table/table';
  import {
    deviceTypeList,
    deviceMatching
  } from '@/api/deviceManagement';
  //查看联系人组件
  import ContactDetails from './components/contactDetails'
  export default {
    components: {
      Table,
      ContactDetails,
      Tree
    },
    data() {
      return {
        options: [{
          value: 'OUT_OF_FENCE',
          label: '离开围栏告警'
        }, {
          value: 'INTO_FENCE',
          label: '进入围栏告警'
        }, {
          value: '3',
          label: '低电量告警'
        }, {
          value: '4',
          label: '离线告警'
        }],
        contactDetailsDialog: false, //查看联系人
        restaurants: [], // 模糊匹配数组
        listQuery: { //查询数据对象
          childFlag: false, //复选框
          warningDate: null, //告警时间
          warningDateStartDate: '', // 开始时间
          warningDateEndDate: '', // 结束时间
          positionDate: null, //定位时间
          positionDateStartDate: '', // 开始时间
          positionDateEndDate: '', // 结束时间
          warningType: '', //告警类型
          imeis: '', //搜索框中的imei
          organizationId: null, //组织id
          deviceTypeDetailId: '',
          alarmTypes:[],
          /**分页 */
          total: 0, //数据总数
          page: 1, //当前页
          size: 10, //大小
          orderBy: '', //排序
        },
        pickerStartDateAlart: {
          disabledDate: (time) => { // 限制开始时间小于结束时间
            // if (this.listQuery.warningDateEndDate != "") {
            //     return time.getTime() > Date.now() || time.getTime() > new Date(this.listQuery.warningDateEndDate);
            // } else {
            //     }
            return time.getTime() > Date.now();
          }
        },
        // 结束时间
        pickerEndDateAlart: {
          disabledDate: (time) => { // 限制开始时间小于结束时间
            // if (this.listQuery.warningDateStartDate != "") {
            //     return time.getTime() > Date.now() || time.getTime() < new Date(this.listQuery.warningDateStartDate)-8.64e7;
            // } else {
            //     }
            return time.getTime() > Date.now();
          }
        },
        pickerStartDatePosition: {
          disabledDate: (time) => { // 限制开始时间小于结束时间
            // if (this.listQuery.positionDateEndDate != "") {
            //     return time.getTime() > Date.now() || time.getTime() > new Date(this.listQuery.positionDateEndDate);
            // } else {
            //     }
            return time.getTime() > Date.now();
          }
        },
        // 结束时间
        pickerEndDatePosition: {
          disabledDate: (time) => { // 限制开始时间小于结束时间
            // if (this.listQuery.positionDateStartDate != "") {
            //     return time.getTime() > Date.now() || time.getTime() < new Date(this.listQuery.positionDateStartDate)-8.64e7;
            // } else {
            //     }
            return time.getTime() > Date.now();
          }
        },
        warningTypeList: [{
            value: 2,
            label: '断电报警'
          },
          {
            value: 10,
            label: '进入卫星盲区报警'
          },
          {
            value: 11,
            label: '出GPS盲区报警'
          },
          {
            value: 14,
            label: '外电低电报警'
          },
          {
            value: 33,
            label: '开关未关报警'
          },
          {
            value: 34,
            label: '摄像头通讯故障报警'
          },
          {
            value: 84,
            label: '外接GPS天线报警'
          },
          {
            value: 'out',
            label: '出围栏报警'
          }
        ], //告警类型下拉框数据
        activeNames: '', // 折叠面板
        deviceTypeList: [], //设备类型列表
        table: { //表格配置对象
          configItem: true, //是否开启表格项配置
          stripe: false, //是否为斑马纹
          loading: false, // 数据加载loading
          data: [], //表格渲染数据
          header: [{
              type: 'index',
              label: '序号',
              width: '55',
              ifShow: true,
            },
            {
              prop: 'imei',
              label: 'IMEI',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'deviceName',
              label: '设备名称',
              minWidth: '180',
              ifShow: true,
              tooltip: true
            },
            {
              prop: 'deviceModel',
              label: '设备型号',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },            
            {
              prop: 'sim',
              label: 'SIM卡号',
              minWidth: '180',
              ifShow: true,
              tooltip: true
            },
            {
              prop: 'orgName',
              label: '组织机构',
              minWidth: '180',
              ifShow: true,
              tooltip: true
            },
            {
              prop: 'alarmType',
              label: '警告类型',
              minWidth: '180',
              ifShow: true,
              tooltip: true,
              render: (h, param) => {
                if (param.alarmType == 'INTO_FENCE') {
                  return h('div', [
                      h('span', {
                          style: {
                              marginRight: '8px',
                              color: 'blue',
                          },
                      }, '进入围栏告警'),
                  ]);
                } else if (param.alarmType == 'OUT_OF_FENCE') {
                  return h('div', [
                      h('span', {
                          style: {
                              marginRight: '8px',
                              color: 'blue',
                          },
                      }, '离开围栏告警'),
                  ]);
                }
              }
            },
            {
              prop: 'alarmTime',
              label: '警告时间',
              minWidth: '180',
              sortable: 'column',
              ifShow: true,
              tooltip: true
            },
          ]
        },
        userList: {}, //组织下的用户
        toSeeConcat: {
          page: 1, // 当前页
          size: 10, // 每页显示数
          organizationId: null
        },
      }
    },
    created () {
      this.listQuery.imeis = this.$route.params.imei;
      this.listQuery.alarmTypes = [this.$route.params.alarmTypes];
    },
    mounted() {
      this.listQuery.warningType = this.$route.params.alarmName ? this.warningTypeList.filter((v, i, r) => {
        return v.label == this.$route.params.alarmName
      })[0].value : (this.$route.query.warningType ? this.$route.query.warningType : null)
      //获取当前用户的组织 id
      this.listQuery.organizationId = this.$store.getters.organizationId;
      if (this.$route.query.imeis) {
        this.listQuery.warningDateStartDate = this.$route.query.warningDateStartDate;
        this.listQuery.childFlag = this.$route.query.childFlag;
        this.listQuery.warningDateEndDate = this.$route.query.warningDateEndDate;
        this.listQuery.organizationId = this.$route.query.organizationId;
        this.listQuery.imeis = this.$route.query.imeis;
      }
      if (this.$route.query.imei) {  // 告警提示查看详情
        this.listQuery.imeis = this.$route.query.imei;
      }
      console.log(this.listQuery, 999);
      //获取设备型号
      this.getDeviceTypeList();
      //初始获取列表信息
      this.getAlarmDetails();
    },
    methods: {
      /**
       * 树形结构点击事件
       * @param { Object } node 当前点击节点
       */
      currentNode(node) {
        this.listQuery.organizationId = node.id;
        this.getAlarmDetails();
      },
      /**
       * 获取设备类型列表
       */
      getDeviceTypeList() {
        getDeviceType({
          openPage: '1'
        }).then((res) => {
          if (res.data.code == 0) {
            res.data.data.rows.forEach((v, i, r) => {
              let deviceTypeObj = {};
              deviceTypeObj.value = v.id;
              deviceTypeObj.label = v.deviceModel;
              this.deviceTypeList.push(deviceTypeObj);
            })
            this.listQuery.deviceTypeDetailId = this.$route.params.deviceTypeDetailId ? this.deviceTypeList.filter((
              v, i, r) => {
              return v.label == this.$route.params.deviceTypeDetailId
            })[0].value : null
          }
        })
      },
      /**
       * 通过组织id 获取用户信息列表
       * @param {Number } organizationId 组织 id
       */
      seeUserList(val) {
        this.toSeeConcat.size = 10;
        this.toSeeConcat.page = 1;
        this.toSeeConcat.organizationId = val;
        this.getUserList();
      },
      /** 模糊查询imei */
      querySearch(queryString, cb) {
        if (queryString.trim() == '') {
          cb([])
          return;
        }
        deviceMatching({
          imei: queryString
        }).then(res => {
          if (!res.data.code) {
            let arr = [];
            for (var i = 0; i < res.data.data.length; i++) { // 处理成模糊查询的数据
              let obj = {};
              obj.value = res.data.data[i];
              arr.push(obj);
            }
            cb(arr);
          } else {
            this.$message.error(res.data.msg);
          }
        })
      },
      /**
       * 分页切换的方法
       * @param { Object } val 分页传过来的数据
       */
      getUserList(val) {
        //分页切换  更新父组件的值
        this.toSeeConcat.size = val ? val.size : this.toSeeConcat.size;
        this.toSeeConcat.page = val ? val.page : this.toSeeConcat.page;
        const paramUser = {
          limit: this.toSeeConcat.size,
          organizationId: this.toSeeConcat.organizationId,
          page: this.toSeeConcat.page,
        }
        getUserListByOrganizationId(paramUser).then((res) => {
          if (res.data.code == 0) {
            this.userList = res.data.data;
          }
        })
      },
      /**
       * 获取警告详情列表信息
       */
      getAlarmDetails(val) {
        if (this.listQuery.warningDateStartDate > this.listQuery.warningDateEndDate || this.listQuery
          .positionDateStartDate > this.listQuery.positionDateEndDate) {
          this.$message.warning('开始时间大于结束时间');
          return;
        }
        const params = {
          alarmStartTime: this.listQuery.warningDateStartDate ? this.listQuery.warningDateStartDate : null,
          alarmEndTime: this.listQuery.warningDateEndDate ? this.listQuery.warningDateEndDate : null,
          organizationId: this.listQuery.organizationId,
          // locationStartTime: this.listQuery.positionDateStartDate ? this.listQuery.positionDateStartDate : null,
          // locationEndTime: this.listQuery.positionDateEndDate ? this.listQuery.positionDateEndDate : null,
          pageSize: this.listQuery.size,
          imeis: this.listQuery.imeis,
          alarmTypes: this.listQuery.alarmTypes.toString(),
          childFlag: this.listQuery.childFlag,
          // alarmType: this.listQuery.warningType,
          pageIndex: this.listQuery.page,
          fullUserId:getOrgCode(),
          // alarmTypeSet:[],
          readStatus:null
        }
        if(val == "search"){
            param.pageIndex =1 ;
        }
        alarmDetail(params).then((res) => {
          if (res.code == 200) {
            this.table.data = res.data;
            this.listQuery.total = res.count;
          } else {
            this.table.data = [];
            this.listQuery.total = 0;
          }
        })
      },
      /**
       * 搜索
       */
      handleSearch(val) {
        this.getAlarmDetails(val);
      },
      /**
       * 重置搜索导航栏
       * @param { Object } formName 当前表单的ref值
       */
      restForm(formName){
          this.$refs[formName].resetFields();
      },
    }
  }

</script>
<style lang="scss" scoped>
//   .contant {
//     margin-top: 20px;

//     .page {
//       float: right;
//       margin-top: 10px;
//     }

//     .more-search {
//       margin: 10px 0;

//       .el-collapse-item__arrow {
//         margin: 0;
//       }

//       .option-btn {
//         margin-top: 10px;
//       }
//     }
//   }

</style>
