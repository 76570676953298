import request from '@/utils/request';
//获取警告详情列表
// export function alarmDetail(data) {
//     return request({
//         url: '/alarm/alarmDetail',//请求路径
//         method: 'post',
//         data
//     });
// }

export function alarmDetail(data) {
    return request({
        url: '/alarm/pageAlarmDetailExt',//请求路径
        method: 'post',
        data
    });
}